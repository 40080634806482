// Global actions for apps
export const LOAD_AETS = 'LOAD_AETS'
export const SET_TAB = 'SET_TAB'

// AutoQuery QueryList Actions
export const AQ_ADD_QUERY_TO_LIST = 'AQ_ADD_QUERY_TO_LIST'
export const AQ_ADD_EMPTY_QUERY = 'AQ_ADD_EMPTY_QUERY'
export const AQ_EDIT_COLUMN_QUERY = 'AQ_EDIT_COLUMN_QUERY'
export const AQ_EDIT_CELL_QUERY = 'AQ_EDIT_CELL_QUERY'
export const AQ_REMOVE_QUERY = 'AQ_REMOVE_QUERY'
export const AQ_EMPTY_QUERY = 'AQ_EMPTY_QUERY'

// AutoQuery ResultList Actions
export const AQ_ADD_STUDY_RESULT = 'AQ_ADD_STUDY_RESULT'
export const AQ_ADD_SERIES_DETAILS = 'AQ_ADD_SERIES_DETAILS'
export const AQ_EMPTY_RESULTS_STUDY_SERIES = 'AQ_EMPTY_RESULTS_STUDY_SERIES'
export const AQ_REMOVE_STUDY_RESULT = 'AQ_REMOVE_STUDY_RESULT'
export const AQ_REMOVE_SERIES_RESULT = 'AQ_REMOVE_SERIES_RESULT'

//DeleteTools Actions
export const ADD_STUDY_DELETE_LIST = 'ADD_STUDY_DELETE_LIST'
export const REMOVE_PATIENT_DELETE_LIST = 'REMOVE_PATIENT_DELETE_LIST'
export const REMOVE_STUDY_DELETE_LIST = 'REMOVE_STUDY_DELETE_LIST'
export const EMPTY_DELETE_LIST = 'EMPTY_DELETE_LIST'

//Anon Actions
export const ADD_ANON_LIST = 'ADD_ANON_LIST'
export const EMPTY_ANON_LIST = 'EMPTY_ANON_LIST'
export const REMOVE_PATIENT_ANON_LIST = 'REMOVE_PATIENT_ANON_LIST'
export const REMOVE_STUDY_ANON_LIST = 'REMOVE_STUDY_ANON_LIST'
export const SAVE_NEW_VALUES = 'SAVE_NEW_VALUES'
export const SAVE_ANON_PROFILE = 'SAVE_ANON_PROFILE'
export const AUTOFILL = 'AUTOFILL'

//Export Actions
export const ADD_EXPORT_CONTENT = 'ADD_EXPORT_CONTENT'
export const REMOVE_STUDY_EXPORT_LIST = 'REMOVE_STUDY_EXPORT_LIST'
export const REMOVE_SERIES_EXPORT_LIST = 'REMOVE_SERIES_EXPORt_LIST'
export const EMPTY_EXPORT_LIST = 'EMPTY_EXPORT_LIST'

//Log In / out
export const LOG_OUT = 'LOG_OUT'
export const LOG_IN = 'LOG_IN'